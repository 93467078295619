import React, {Component} from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min';
import './App.css';
import * as logo from './logo.png';
require('animate.css');

class App extends Component {
	constructor(props){
		super(props);
		
		this.state = {
			files: {},
			hovered: null,
			selected: null
		};
	}
	
	componentDidMount(){
		this._getFiles();
	}
	
	render(){
		let keySelected = null;
		let labelSelected = null;
		let scripts = [];
		
		if(this.state.selected !== null && Object.keys(this.state.files).length > 0){
			keySelected = Object.keys(this.state.files)[this.state.selected];
			labelSelected = keySelected.split('-').join(' ');
			scripts = this.state.files[keySelected].map((script) => {
				return window.location.origin + '/cloud/' + keySelected + '/' + script;
			});
		}
		
		return (
			<div>
				<div className={"animated fadeIn p-2 bg-light border-bottom"}>
					<img src={logo} alt="DS Cloud" height={70} className={"d-inline"}/>
					<h2 className="font-titillium text-primary d-inline">DS Cloud</h2>
				</div>
				
				<div hidden={this.state.selected !== null} className="row p-3">
					{Object.keys(this.state.files).map((dir, k) => {
						const bgClass = this.state.hovered === k ? 'bg-primary text-light' : 'bg-light';
						const label = dir.split('/').slice(-1)[0].split('-').join(' ');
						const fadeClass = this.state.selected === null || this.state.selected === k ? 'fadeIn' : 'fadeOut';
						
						return <div className={"col-md-4 animated " + fadeClass} key={k}>
							<p onClick={this._setSelected.bind(this, k)} onMouseLeave={this._setHovered.bind(this, null)} onMouseOver={this._setHovered.bind(this, k)} className={"ucfirst cursor-pointer px-3 py-4 border text-center " + bgClass}>
							   {label}
							</p>
						</div>;
					})}
				</div>
				
				<div hidden={this.state.selected === null} className={"card mx-3 mt-3 rounded-0 animated fadeInUp"}>
					<div className="card-header">
						<span className="ucfirst float-left">{labelSelected}</span>
						<i onClick={this._setSelected.bind(this, null)} className="fas fa-times float-right mt-1 cursor-pointer"/>
					</div>
					<div className="card-body">
						<ul className="list-group">
							{scripts.map((script, k) => {
								return <li key={k} className="list-group-item">
									<a href={script} target={'_blank'}>{script}</a>
								</li>;
							})}
						</ul>
					</div>
				</div>
			</div>
		);
	}
	
	/**
	 * @private
	 */
	_getFiles(){
		const DOMAIN_LIST_FILE = window.location.hostname === 'localhost'
			? 'http://127.0.0.1:8888'
			: window.location.origin + '/'
		;
		
		fetch(DOMAIN_LIST_FILE + '/listfiles.php')
		.then(response => response.json())
		.then(response => {
			this.setState({files: response});
		});
	}
	
	/**
	 * @param key
	 * @private
	 */
	_setHovered(key){
		this.setState({hovered: key});
	}
	
	/**
	 * @param key
	 * @private
	 */
	_setSelected(key){
		this.setState({selected: key});
	}
}

export default App;
